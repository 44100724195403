import React from 'react';
import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';
// import 'prismjs/themes/prism.css';
import Helmet from 'react-helmet';

const BlogPage = ({ data }) => {
  let posts = data.allMarkdownRemark.edges.map((p) => p.node);

  return (
    <Layout>
      <Helmet>
        <body page-name="blog" />
      </Helmet>
      <main>
        <h1>writing</h1>

        <h2>
          <em>Web Fundamentals</em>
        </h2>
        <div style={{ margin: '1rem' }}>
          <p>
            An ever-changing and growing corpus of information for those getting
            started in web development; material adapted from my in-person
            workshops.
          </p>

          <p>
            Read now at{' '}
            <a href="https://web-fundamentals.dev">
              <code>web-fundamentals.dev</code>
            </a>
          </p>
        </div>

        <hr />

        <h2 id="blog">blog</h2>
        {posts.map(({ frontmatter: fm }, i) => (
          <div className="post" key={i} style={{ marginLeft: '1rem' }}>
            <h3>
              <Link to={fm.path}>{fm.title}</Link>
            </h3>
            <div className="subtitle">{fm.subtitle}</div>
            <div className="date">{fm.date}</div>
          </div>
        ))}
      </main>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`;
